import { Container, AppBar, Toolbar, Typography, IconButton, Button, Card, CardHeader } from '@mui/material';
import { ArrowBackOutlined, CardMembershipOutlined, PaymentOutlined, PaymentsOutlined, PixOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { toReal, createNewTheme } from '../functions/utils';
import * as React from 'react';

import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import Cookies from 'js-cookie';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@fontsource/roboto/400.css';

function Pagamentos() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [selectedCard, setSelectedCard] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [valorLevar, setValorLevar] = useState("0,00");
  const [textButton, setTextButton] = useState("Continuar sem troco");
  const [troco, setTroco] = useState("");
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleModalTroco = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    selectedCard ? setButtonEnabled(false) : setButtonEnabled(true)
    setOpen(false);
  };

  const handleBack = () => {
    navigate("/enderecos", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  const handlePagamentos = (isTroco) => {
    if (isTroco) {
      Cookies.set('fmP', selectedCard, { expires: 7 });
      Cookies.set('sT', troco, { expires: 7 });
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
      return
    }
    if (selectedCard === "dinheiro") {
      handleModalTroco()
      parseFloat(valorLevar.replaceAll(".", "").replaceAll(",", '.')) < 0 ? setButtonEnabled(true) : setButtonEnabled(false)
    } else {
      Cookies.set('fmP', selectedCard, { expires: 7 });
      Cookies.remove('sT');
      navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
    }
  };

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId);
    setButtonEnabled(false)
  };

  const getValorTotal = () => {
    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      let valorTotal = 0
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      let bloquearCarrinho = Cookies.get('bCrt');
      if (valorTotal === 0 && !bloquearCarrinho && Cookies.get("vPt")) {
        Cookies.remove('crU');
        navigate("/", { replace: true, state: { page: page } })
      }
      if(valorTotal === 0) {
        Cookies.set('fmP', "Combinar", { expires: 7 });
        Cookies.remove('sT');
        navigate("/finalizar", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
      }
      setValorTotalCarrinho(toReal(valorTotal))
    }
  }

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    if (colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    getInfos();
    getValorTotal();
    const pagamento = Cookies.get('fmP');
    setSelectedCard(pagamento);
    if (pagamento) {
      setButtonEnabled(false)
    }
  }, []);

  const handleTroco = (event) => {
    setTroco(event.target.value);
    const trocoLevar = parseFloat(event.target.value.replaceAll(".", "").replaceAll(",", ".")) - parseFloat(valorTotalCarrinho.replaceAll(".", "").replaceAll(",", "."))
    isNaN(trocoLevar) ? setValorLevar("0,00") : setValorLevar(toReal(trocoLevar))
    trocoLevar < 0 ? setButtonEnabled(true) : setButtonEnabled(false)

    event.target.value ? setTextButton("Continuar") : setTextButton("Continuar sem troco")
  };

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/formas-pagamento.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        setFormasPagamento(response.data[0]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const returnFormas = (formas) => {
    if (!formas.formas_pagamento) {
      return
    }
    const pagamentosArray = formas.formas_pagamento.split("--");
    const creditodebito = pagamentosArray[1].split(",")
    const vrva = pagamentosArray[2].split(",")
    if (pagamentosArray[3]) {
      Cookies.set('cvP', pagamentosArray[3], { expires: 7 });
    }

    return <>
      {pagamentosArray[3] && (
        <>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'pix' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick('pix')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PixOutlined style={{ color: "#464545" }} />
              }
              title="PIX"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </>
      )}
      {pagamentosArray[4] === "dinheiro" && (
        <>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'dinheiro' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick('dinheiro')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PaymentsOutlined style={{ color: "#464545" }} />
              }
              title="DINHEIRO"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </>
      )}

      {pagamentosArray[5] === "link" && (
        <>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'link de pagamento' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick('link de pagamento')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <LinkIcon style={{ color: "#464545" }} />
              }
              title="LINK DE PAGAMENTO"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </>
      )}

      {creditodebito[0] && (
        <>
          {creditodebito.map((cartoes) => (
            <>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === cartoes ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick(cartoes)}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <PaymentOutlined style={{ color: "#464545" }} />
                  }
                  title={cartoes.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "8px" }}></div>
            </>
          ))}
        </>
      )}

      {vrva[0] && (
        <>
          {vrva.map((vales) => (
            <>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === vales ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick(vales)}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <CardMembershipOutlined style={{ color: "#464545" }} />
                  }
                  title={vales.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "8px" }}></div>
            </>
          ))}
        </>
      )}
    </>
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Forma de pagamento
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "70px" }}></div>

        <Dialog
          open={open}
          onClose={handleCloseDialog}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              handlePagamentos(true)
              handleCloseDialog();
            },
          }}
        >
          <DialogTitle>Precisa de troco?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Digite o valor em dinheiro para levar o valor exato do troco:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Valor"
              value={troco}
              onChange={handleTroco}
              fullWidth
            />
          </DialogContent>
          <DialogContentText style={{ fontSize: "12px", paddingLeft: "25px", marginTop: "-16px" }}>
            Valor total: R$ {valorTotalCarrinho}<br />
            Valor troco: R$ {valorLevar}
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleCloseDialog} style={{ textTransform: "capitalize", fontWeight: "700" }}>Voltar</Button>
            <Button type="submit" disabled={buttonEnabled} style={{ textTransform: "capitalize", fontWeight: "700" }}>{textButton}</Button>
          </DialogActions>
        </Dialog>

        <Typography style={{ color: "#999", fontSize: "14px", fontWeight: "bold" }}>
          Selecione um meio de pagamento para finalizar o seu pedido.
        </Typography>

        <div style={{ height: "12px" }}></div>

        {returnFormas(formasPagamento)}

        <div style={{ position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "16px 16px 16px 16px" }}>
          <Button fullWidth disabled={buttonEnabled} variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700" }} onClick={() => handlePagamentos(false)}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Pagamentos;