import { Alert, AppBar, Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider, Drawer, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, TextField, Toolbar, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, stringAvatar } from '../../functions/utils';

import { BarChart } from '@mui/x-charts/BarChart';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Cookies from 'js-cookie';
import axios from 'axios';
import { HomeOutlined, ArticleOutlined, RestaurantOutlined, ListAltOutlined, QrCodeOutlined, EditOutlined, LayersOutlined, SettingsOutlined, ContactSupportOutlined, Search, ShareOutlined, Close, ContentCopy } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "8px",
};

function Home() {

  const state = useLocation().state;
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const [statusLoja, setStatusLoja] = useState("aberto");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {

    setColorPrimary(Cookies.get("clPmr"))
    setColorSecondary(Cookies.get("clScr"))
    setColorTertiary(Cookies.get("clTcr"))

  }, []);

  const handleConfigure = () => {

  }

  const handleShare = () => {
    handleOpenModal()
  }

  const handleStatus = (event) => {
    setStatusLoja(event.target.value)
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto", height: 75 }}>
        <Toolbar>

          {isMobile ? <></> :
            <>
              <TextField
                color='secondary'
                placeholder="Buscar"
                sx={{ m: 1, width: '25ch', marginLeft: "315px" }}
                InputProps={{
                  endAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                }}
              />

              <IconButton size="large" color="inherit" style={{ color: "#FFF", marginLeft: "auto" }} onClick={handleShare}>
                <ShareOutlined />
              </IconButton>
              <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Loja:
                <div style={{ width: 10 }}></div>
                <FormControl style={{ width: 150 }}>
                  <Select
                    value={statusLoja}
                    placeholder="Status"
                    onChange={handleStatus}
                    color="secondary"
                    style={{ color: statusLoja === "aberto" ? "green" : "tomato" }}
                  >
                    <MenuItem value={"aberto"} style={{ color: "green" }}>Aberto</MenuItem>
                    <MenuItem value={"fechado"} style={{ color: "tomato" }}>Fechado</MenuItem>
                  </Select>
                </FormControl>
                <div style={{ width: 10 }}></div>
                <Avatar {...stringAvatar("Joelson Lucas")} style={{ margin: "auto", width: "38px", height: "38px", fontSize: 14 }} />

              </Typography>
            </>
          }
        </Toolbar>
      </AppBar>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box sx={style}>
          <Typography style={{ fontSize: 18, color: "#464545", fontWeight: "bold", display: "flex", padding: 16 }}>
          <ShareOutlined /> <div style={{ width: 10}} /> Compartilhar <Close style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleCloseModal} />
          </Typography>
          <Divider />
          <Typography style={{ fontSize: 16, padding: 16, fontWeight: "bold" }}>
            Copie o link abaixo para enviar<br />
            <Typography style={{ width: "100%", border: "solid 1px #DCDBDB", padding: 10, borderRadius: "4px", cursor: "pointer" }}>dedicado.mely.online <ContentCopy style={{ float: "right"}} /></Typography>
          </Typography>
        </Box>
      </Modal>

      <div style={{ top: 90, bottom: 0, right: 0, left: isMobile ? 0 : 315, position: "absolute" }}>
        <Grid container spacing={2} style={{ padding: 16 }}>
          <Grid item xs={12} sm={12} md={8}>
            <div style={{ height: "30px" }}></div>
            <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Atalhos</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div style={{ height: "30px" }}></div>
            <Typography style={{ fontWeight: "bold", color: "#1B1B1B" }}>Vendas</Typography>

            <Card variant="outlined" sx={{ borderRadius: "8px" }}>
              <CardHeader
                title={
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <strong style={{ fontSize: 16, color: "#999999" }}>R$</strong>
                    <strong style={{ fontSize: 22, color: "#464545" }}> 100,00</strong>


                    <strong style={{ fontSize: 16, color: "#999999", marginLeft: "auto" }}> Ganhos (Semanal)</strong>
                    <strong style={{ fontSize: 22, color: "#464545" }}>
                      <IconButton size="large" color="inherit" onClick={handleConfigure}>
                        <SettingsOutlined />
                      </IconButton>
                    </strong>
                  </Typography>
                }
                style={{ color: "#33540E" }}
              />

              <Divider />
              <CardContent>
                <BarChart
                  xAxis={[{ scaleType: 'band', data: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'] }]}
                  series={[{ data: [4, 10, 2, 4, 6, 7, 20] }]}
                  height={200}
                  margin={{ top: 0, left: 0, right: 0, bottom: 20 }}
                  leftAxis={null}
                  slotProps={{
                    bar: {
                      clipPath: `inset(0px round 10px 10px 10px 10px)`,
                    },

                  }}
                  bottomAxis={{
                    disableLine: true,
                    disableTicks: true,
                  }}
                />

              </CardContent>
              <div style={{ height: "16px" }}></div>
            </Card>

            <div style={{ height: 30 }}></div>
            <Typography style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
              Loja:
              <div style={{ width: 10 }}></div>
              <FormControl style={{ width: 150 }}>
                <Select
                  value={statusLoja}
                  placeholder="Status"
                  onChange={handleStatus}
                  color="secondary"
                  style={{ color: statusLoja === "aberto" ? "green" : "tomato" }}
                >
                  <MenuItem value={"aberto"} style={{ color: "green" }}>Aberto</MenuItem>
                  <MenuItem value={"fechado"} style={{ color: "tomato" }}>Fechado</MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: 10 }}></div>
              <IconButton size="large" color="inherit" style={{ color: "#464545" }} onClick={handleConfigure}>
                <SettingsOutlined />
              </IconButton>
            </Typography>

            <div style={{ height: 20 }}></div>


            <Alert
              variant="outlined"
              severity="info"
              onClose={() => { }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                color: "#464545", fontSize: 14
              }}
            >
              <div>
                <b style={{ fontSize: 16 }}>Status da loja</b><br />
                Altere ou configure o status da sua loja a qualquer momento
              </div>
            </Alert>
          </Grid>
        </Grid>
      </div>

      {isMobile ? <></> :
        <Drawer
          variant="permanent"
          anchor="left"
        >
          <div style={{ height: 80 }}></div>
          <List style={{ width: 315, padding: 20 }}>
            <ListItem button style={{ background: "#F2F2F2", borderRadius: 6 }}>
              <HomeOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Home" primaryTypographyProps={{ color: "tomato", fontWeight: "bold" }} />
            </ListItem>
          </List>
          <div style={{ height: 10 }}></div>
          <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Geral</Typography>
          <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
            <ListItem button style={{ borderRadius: 6 }}>
              <ArticleOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Pedidos" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button style={{ borderRadius: 6 }}>
              <RestaurantOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Cardápio" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button style={{ borderRadius: 6 }}>
              <ListAltOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Inventário" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button style={{ borderRadius: 6 }}>
              <QrCodeOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="QR Code" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button style={{ borderRadius: 6 }}>
              <EditOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Personalizar" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button style={{ borderRadius: 6 }}>
              <LayersOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="PDV" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
              <Typography style={{ background: "#F94A3D", color: "white", padding: "0px 10px 0px 10px", borderRadius: 4 }}>NOVO</Typography>
            </ListItem>
          </List>

          <div style={{ height: 20 }}></div>
          <Typography style={{ fontSize: 14, fontWeight: "bold", color: "#999", paddingLeft: 40 }}>Outras funções</Typography>
          <List style={{ width: 315, padding: "5px 20px 0px 20px" }}>
            <ListItem button style={{ borderRadius: 6 }}>
              <SettingsOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Configurações" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
            <ListItem button style={{ borderRadius: 6 }}>
              <ContactSupportOutlined style={{ marginRight: 14, color: "#464545" }} />
              <ListItemText primary="Suporte" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
            </ListItem>
          </List>
        </Drawer>
      }


    </ThemeProvider>
  );
}

export default Home;