import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Fidelidade from './pages/Fidelidade';
import AppHome from './AppHome';
import Informacoes from './pages/Informacoes';
import Carrinho from './pages/Carrinho';
import Enderecos from './pages/Enderecos';
import Pagamentos from './pages/Pagamentos';
import Finalizar from './pages/Finalizar';
import Pedido from './pages/Pedido';
import PerfilLoja from './pages/PerfilLoja';
import AvaliacoesLoja from './pages/AvaliacoesLoja';
import InformacoesPerfil from './pages/InformacoesPerfil';
import Senha from './pages/Senha';
import SelecionarEndereco from './pages/SelecionarEndereco';
import Finalizado from './pages/Finalizado';
import Redirect from './pages/estabelecimento/Redirect';
import Login from './pages/estabelecimento/Login';
import Home from './pages/estabelecimento/Home';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppHome />} />
        <Route path="/fidelidade" element={<Fidelidade />} />
        <Route path="/informacoes" element={<Informacoes />} />
        <Route path="/informacoes-perfil" element={<InformacoesPerfil />} />
        <Route path="/senha" element={<Senha />} />
        <Route path="/carrinho" element={<Carrinho />} />
        <Route path="/enderecos" element={<Enderecos />} />
        <Route path="/selecionar-endereco" element={<SelecionarEndereco />} />
        <Route path="/pagamentos" element={<Pagamentos />} />
        <Route path="/finalizar" element={<Finalizar />} />
        <Route path="/finalizado" element={<Finalizado />} />
        <Route path="/pedido" element={<Pedido />} />
        <Route path="/perfil-loja" element={<PerfilLoja />} />
        <Route path="/avaliacoes-loja" element={<AvaliacoesLoja />} />

        <Route path="/estabelecimento" element={<Redirect />} />
        <Route path="/estabelecimento/login" element={<Login />} />
        <Route path="/estabelecimento/home" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
