import { Container, AppBar, Toolbar, IconButton, Typography, Avatar, List, ListItem, ListItemButton, ListItemText, TextField, InputAdornment, OutlinedInput, Button, SwipeableDrawer, Skeleton, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LockOutlined, MailOutline, Widgets } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';

import backgroundImage from "../../imagens/bg-1.png";
import logoImage from "../../imagens/logo.png";
import LoadingComponent from '../../components/LoadingComponent';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';

function Login() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [urlBase, setUrlBase] = useState("");
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "217, 217, 217");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "217, 217, 217");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "217, 217, 217");
  const navigate = useNavigate();


  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
  };

  useEffect(() => {
    handleOpenLoading();

    setColorPrimary(Cookies.get("clPmr"))
    setColorSecondary(Cookies.get("clScr"))
    setColorTertiary(Cookies.get("clTcr"))

    if (!Cookies.get('UBs')) {
      navigate("/estabelecimento");
    }
    setUrlBase(Cookies.get('UBs'))
    const isLogged = Cookies.get("isLogged");
    const isStore = Cookies.get("isStore");

    if (isLogged && isStore) {
      navigate("/estabelecimento/home", { replace: true, state: { page: "home" } });
      handleCloseLoading();
      return
    }
    handleCloseLoading();
  })

  const handleLogin = () => {
    const urlbase = Cookies.get('UBs');
    handleOpenLoading();
    const post_url = urlbase + "/login-estabelecimento.php";
    const json = JSON.stringify({
      email: email,
      senha: senha,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        console.log(response)
        if (response === null || response === undefined) {
          handleSnack("A senha digitada para o e-mail é inválida, se estiver com problemas em sua senha entre em contato com nosso suporte.");
          handleCloseLoading();
        }
        else {
          if (response.resposta === "email invalido") {
            handleSnack("O e-mail digitado não é válido, verifique e tente novamente.");
            handleCloseLoading();
          } else {
            for (let user of response) {
              Cookies.set('dsEst', user.descricao, { expires: 7 });
              Cookies.set('emEst', user.email, { expires: 7 });
              Cookies.set('endEst', user.endereco, { expires: 7 });
              Cookies.set('fMsP', user.formas_pagamento, { expires: 7 });
              Cookies.set('hREst', user.horarios, { expires: 7 });
              Cookies.set('iEst', user.idestabelecimentos, { expires: 7 });
              Cookies.set('nmEst', user.nome, { expires: 7 });
              Cookies.set('txEst', user.taxa_entrega, { expires: 7 });
              Cookies.set('tEst', user.telefones, { expires: 7 });
              Cookies.set('tsEst', user.tipo_segmento, { expires: 7 });
              Cookies.set('UBs', user.url_base, { expires: 7 });
              Cookies.set('isLogged', true, { expires: 7 });
              Cookies.set('isStore', true, { expires: 7 });
              navigate("/estabelecimento/home", { replace: true, state: { page: "home" } });
            }
            handleCloseLoading();
          }
        }

      })
      .catch(error => {
        handleSnack("Não foi possivel entrar em sua conta, tente novamente mais tarde.");
        handleCloseLoading();
      });
  }

  const handleEsqueciSenha = () => {
    const urlbase = Cookies.get('UBs');
    handleOpenLoading();
    const post_url = urlbase + "/reenviar-senha-estabelecimento.php";
    const json = JSON.stringify({
      email: email,
    });

    axios.post(post_url, json)
      .then(resp => {
        const response = resp.data
        if (response === null || response === undefined) {
          handleSnack('Não foi possivel reenviar sua senha, verifique sua conexão e tente novamente.')
          handleCloseLoading();
        }
        else {
          if (response.resposta === "email invalido") {
            handleSnack('O email não é válido, verifique e tente novamente.')
            handleCloseLoading();
          } else if (response.resposta === "email nao cadastrado") {
            handleSnack("O email digitado não possui um cadastro, verifique e tente novamente.")
            handleCloseLoading();
            return;
          } else if (response.resposta === "falha") {
            handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
            handleCloseLoading();
            return;
          } else {
            handleSnack("Um email de redefinição foi enviado para você.")
            handleCloseLoading();
          }
        }
      })
      .catch(error => {
        handleSnack("Não foi possivel entrar em sua conta, tente novamente mais tarde.");
        handleCloseLoading();
      });
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
        }}>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <Box
          sx={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            textAlign: 'center',
          }}
        >
          <div style={{ height: 10 }}></div>

          <Typography style={{ textAlign: "center", fontSize: "22px" }}>
            <img src={`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`} style={{ width: 150 }} /><br />
            <div style={{ height: 10 }}></div>
            ÁREA DO PARCEIRO - LOGIN
          </Typography>

          <div style={{ height: 20 }}></div>

          <TextField
            fullWidth
            hiddenLabel
            value={email}
            onChange={handleEmail}
            variant="outlined"
            size="large"
            label="E-mail"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              ),
            }}
          />

          <div style={{ height: 20, }}></div>

          <TextField
            fullWidth
            hiddenLabel
            value={senha}
            onChange={handleSenha}
            variant="outlined"
            size="large"
            label="Senha"
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
            }}
          />

          <div style={{ height: 40 }}></div>

          <Button variant="contained" size="large" fullWidth aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold", color: "white", minWidth: "400px" }} onClick={handleLogin}>
            Login
          </Button>

          <div style={{ height: 10 }}></div>
          <Button variant="text" size="large" aria-label="settings" style={{ textTransform: "capitalize", fontWeight: "bold", width: "150px" }} onClick={handleEsqueciSenha}>
            Esqueci a senha
          </Button>
          <Typography style={{ display: "flex", justifyContent: "center", fontSize: 14, color: "white", position: "fixed", bottom: 0, left: 0, right: 0 }}>Desenvolvido por <img src={logoImage} style={{ height: 15, marginLeft: "-4px", marginTop: 3 }} /></Typography>

          <div style={{ height: 20 }}></div>

          <div style={{ position: "fixed", bottom: 0, left: 10, fontSize: 16, color: "white" }}>
            © v2.0.1
          </div>

        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Login;